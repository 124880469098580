import { notifications } from "@mantine/notifications";
import {
	BadRequestException,
	ConflictException,
	InternalServerErrorException,
	NotFoundException,
	UnauthorizedException,
} from "./errors";
import { useAppState } from "./states";

type FetcherParams = {
	path: string;
	opts: RequestInit;
};

export const apiFetcher = async <
	// biome-ignore lint/suspicious/noExplicitAny: default value
	T = any,
>({
	path,
	opts,
}: FetcherParams) => {
	const { faskes } = useAppState.getState();
	if (faskes === null) throw new Error("Faskes tidak ditemukan");
	const csrf = useAppState.getState().csrf;

	if (csrf === null) throw new Error("CSRF tidak ditemukan");

	const request = await fetch(`${faskes.baseApiUrl}${path}`, {
		...opts,
		headers: {
			...opts.headers,
			"x-tenant-id": faskes.tenantId,
			"x-csrf-token": csrf,
		},
		credentials: "include",
	});

	const responseData = await request.json();

	if (!request.ok) {
		const errorCode = responseData?.statusCode || 500;
		const errorMessage = responseData?.message || "Gagal mengambil data";
		switch (errorCode) {
			case 400:
				throw new BadRequestException(errorMessage);
			case 401:
				throw new UnauthorizedException(errorMessage);
			case 404:
				throw new NotFoundException(errorMessage);
			case 409:
				throw new ConflictException(errorMessage);
			case 500:
				throw new InternalServerErrorException(errorMessage);
			default:
				throw new Error(`${errorMessage} (Status: ${errorCode})`);
		}
	}

	return responseData.results as T;
};

export const dateParser = (date: string) => {
	const parts = date.split("/");
	if (parts.length === 3) {
		return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
	}
	return new Date(date);
};

export const handleDateValidation = (date: string) => {
	const parsedDate = dateParser(date);

	if (Number.isNaN(parsedDate.getTime()) || parsedDate.getFullYear() > 9999) {
		notifications.show({
			message: "Tanggal tidak valid! Format harus DD/MM/YYYY.",
			title: "Perhatian!",
			color: "red",
			withCloseButton: true,
		});
		return false;
	}

	return true;
};

export const decodeHTML = (html: string) => {
	const parser = new DOMParser();
	const decodedString =
		parser.parseFromString(html || "", "text/html").body.textContent || "";
	return decodedString;
};
