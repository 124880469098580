export class BadRequestException extends Error {
	statusCode = 400;
	constructor(message = "Bad Request") {
		super(message);
		this.name = "BadRequestException";
	}
}

export class UnauthorizedException extends Error {
	statusCode = 401;
	constructor(message = "Unauthorized") {
		super(message);
		this.name = "UnauthorizedException";
	}
}

export class NotFoundException extends Error {
	statusCode = 404;
	constructor(message = "Not Found") {
		super(message);
		this.name = "NotFoundException";
	}
}

export class ConflictException extends Error {
	statusCode = 409;
	constructor(message = "Conflict") {
		super(message);
		this.name = "ConflictException";
	}
}

export class InternalServerErrorException extends Error {
	statusCode = 500;
	constructor(message = "Internal Server Error") {
		super(message);
		this.name = "InternalServerErrorException";
	}
}
